@media (max-width: 1200px) {
  .license__wrap {
    flex-wrap: wrap;
    overflow: hidden;
  }
  .license__right {
    flex: 0 0 100%;
    min-width: 100%;
  }
  .license__left {
    flex: 0 0 100%;
    min-width: 100%;
  }
}
.license__wrap {
  max-width: 1200px;
  margin: auto;
  display: flex;
}
.license__s6 {
  padding-top: 20px;
  background: radial-gradient(92.26% 159.1% at 36.82% 0%, rgba(224, 224, 224, 0) 0%, #E0E0E0 100%);

  .slick-track {
    padding-bottom: 35px;
  }

  //.license__slide-img {
  //  .slick-list {
  //    padding-left: 0 !important;
  //  }
  //}

  .license__tabs {
      text-align: center;
      padding-top: 6px;
      padding-bottom: 40px;

      ul {
        display: flex;
        flex-wrap: nowrap;
        font-weight: 500;
        font-size: px-to-rem(12px);
        line-height: 134.77%;

        li {
          white-space: nowrap;
          padding: 12px 12px;
          cursor: pointer;
          height: 48px;
          box-sizing: border-box;

          &.slick-current {
            box-shadow: 0 10px 30px rgba(101, 80, 68, 0.1);
            background-color: white;
            position: relative;
            border-radius: 8px;

            &:before {
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 12px 20px 0 20px;
              border-color: #ffffff transparent transparent transparent;
              position: absolute;
              bottom: -12px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }

  }

  h2 {
    text-align: center;
  }


  .license__block {
    position: relative;

    .license__block-item {
      font-size: px-to-rem(12px);
      line-height: 160%;
      height: 300px;
      .slick-slide {
        margin-right: -80px;
      }
      &.certificate {
        height: auto;
      }

      a {
        display: block;
        color: #37B579;
        text-decoration: none;
      }

      p {
        margin: 0;
      }

      h5 {
        margin: 16px 0 8px;
      }

      img {
        max-height: 230px;
        width: auto;
        margin: auto;
      }
    }
  }

}
@media (min-width: 430px) {
  .license__s6 {
    .license__block {
      .license__block-item {
        .slick-slide {
          margin-right: initial;
        }
      }
    }
  }
}
@media (min-width: 594px) {
  .license__s6 {
    .license__tabs {
      ul {
        justify-content: center;
      }
    }
    .license__block {
      .license__block-item {
        .slick-slide {
          margin-right: initial;
        }
      }
    }
  }
}

@media (min-width: 740px) {
  .license__s6 {
    padding-top: 37px;


    .license__block {
      position: relative;

      &:before {
        left: -40px;
        right: -40px;
      }

      .license__block-item {
        font-size: px-to-rem(12px);
        line-height: 160%;
        height: 300px;

        &.certificate {
          height: auto;
        }

        a {
          display: block;
          color: #37B579;
          text-decoration: none;
        }

        p {
          margin: 0;
        }

        h5 {
          margin: 16px 0 8px;
        }

        img {
          max-height: 303px;
          width: auto;
        }
      }
    }

    .license__header {
      .slick-list {
        width: 100%;
        margin: 0 40px;
      }

      .license__tabs {
        padding-top: 12px;
      }
    }
    .license__right {
      height: 367px;

    }
    .license__body {
      display: flex;
      flex-wrap: nowrap;
      box-sizing: border-box;
      div {
        flex: 0 0 50%;
        max-width: 50%;
        &:last-child {
          padding: 0 40px 0 80px;
          box-sizing: border-box;
        }
        h5 {
          font-size: 14px;
          line-height: 160%;
        }
        p {
          font-size: 14px;
          line-height: 160%;
        }
      }
    }
    .certificate__body {
      display: flex;
      flex-wrap: nowrap;
      box-sizing: border-box;
      .license__slide-certificate {
        flex: 0 0 70%;
        max-width: 70%;
        img {
          height: 303px;
        }
      }
      .license__slide-certificate-right {
        flex: 0 0 30%;
        max-width: 30%;
      }
    }

  }
}

@media (min-width: 1200px) {
  .license__s6 {
    display: flex;
    flex-wrap: nowrap;
    padding-top: 90px;
    .slick-track {
      padding-bottom: 0px;
    }
    .license__block {
      position: relative;
      height: 100%;

      .license__block-item {
        height: 353px;
      img {
        max-height: 353px;
        height: 353px;
        width: auto;
      }
    }
    }
    .license__left {
      flex: 0 0 27%;
      max-width: 27%;
      h2 {
        text-align: left;
        font-size: 40px;
        line-height: 47px;
      }
    }

    .license__right {
      flex: 0 0 73%;
      max-width: 73%;
      height: 360px;
      .wrap {
        height: 100%;
      }
    }
    .license__header {
      padding-top: 10px;
      .slick-list {
        width: 100%;
        margin: 0;
      }

      .license__tabs {
        padding-top: 12px;
      }
    }
    .license__tabs {
      max-width: 400px;
      margin: auto;
      padding: 0 36px 0 0;
      box-sizing: border-box;
      margin-left: 0;
      .slick-list {
        overflow: visible;
      }
      ul {
        display: flex;

        li {
          display: inline-block;
          margin: auto;
          padding: 18px 17px;
          width: 100%;
          font-weight: normal;
          font-size: px-to-rem(16px);
          line-height: 134.77%;
          height: 56px;
          text-align: left;
          margin-right: 40px;

          &.active {
            box-shadow: 0 10px 30px rgba(101, 80, 68, 0.1);
            background: url("/templates/img/bg_slider_active.png");
            background-color: white;
            position: relative;
            font-weight: 500;

            &:before {
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 28px 0 28px 18px;
              border-color: transparent transparent transparent #FFFFFF;
              position: absolute;
              top: 0;
              left: 100%;
              transform: translateX(0);
            }
          }
          &.slick-slide {
            &:first-child {
              margin-top: 15px;
            }
            margin-top: 0;
          }
          &.slick-current {
            box-shadow: none;
            background-image: url("/templates/img/bg_slider_active.png");
            background-color: initial;
            position: relative;
            border-radius: 8px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            font-weight: 500;
            &:after {
              content: "";
              z-index: -1;
              position: absolute;
              width: 85%;
              top: 0;
              height: 100%;
              left: 0;
              box-shadow: 17px 8px 26px 8px rgba(101, 80, 68, .1);
              border: 8px;
              border-bottom-right-radius: 50px;
              border-top-right-radius: 50px;
            }
            &:before {
              content: "";
              display: none;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 12px 20px 0 20px;
              border-color: #ffffff transparent transparent transparent;
              position: absolute;
              bottom: -12px;
              left: 100%;
              transform: translateX(0%);
            }
          }
        }
      }
    }
  }
}