.consult__s3 {
  background: radial-gradient(59.77% 318.77% at 26.72% 0%, #6fd5ab 0%, #00b294 100%);
  box-shadow: 0 2.86241px 8.58724px rgba(101, 80, 68, 0.1);
  color: white;
  padding: 40px;
  margin-top: -155px;
  position: relative;
      &:before {
        content: "";

      }

  .consult__title {
    font-weight: bold;
    font-size: px-to-rem(18px);
    line-height: px-to-rem(21px);
    text-align: center;
    margin: 0 0 10px;
    span {
      white-space: nowrap;
    }
  }

  input {
    background: #FFFFFF;
    border-radius: 8px;
    border: none;
    font-size: px-to-rem(14px);
    line-height: 134.77%;
    color: $greendark;
    width: 100%;
    height: 48px;
    padding: 0 20px;
    box-sizing: border-box;
    margin-top: 16px;
  }

  .block-button {
    .btn {
      @include btn {
        background: #425351;
        box-shadow: 0 9.15972px 15.2662px rgba(100, 203, 160, 0.55);
        margin-top: 24px;
        font-weight: bold;
        font-size: px-to-rem(14px);
        line-height: 134.77%;
      }
      &:hover {
        background-color: white;
        color: #425351;
        box-shadow: 0 12px 20px rgba(52, 132, 98, 0.55);
      }
    }
  }

  .consult__pers__data {
    font-weight: normal;
    font-size: px-to-rem(10px);
    line-height: 160%;
    text-align: center;
    margin: 16px 0 0;

    a {
      display: block;
      text-decoration: underline;
      color: white;
      font-weight: 500;
    }
  }
}

@media (min-width: 740px) {
  .consult__s3 {
    padding: 55px 40px 38px;
    margin-top: -170px;
    .consult__wrap {
      max-width: 1200px;
      margin: auto;
    }
    .consult__body{
      max-width: 1000px;
    }
    .consult__title {
      font-size: px-to-rem(24px);
      line-height: 140%;
      text-align: left;
      margin-bottom: 3px;
    }

    form {
      display: flex;
    }

    .form-group {
      width: 33%;
      margin-right: 20px;
      margin-top: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .block-button {
      .btn {
        margin-top: 16px;
      }
    }

    .consult__pers__data {
      font-size: px-to-rem(14px);
      text-align: left;
      margin-top: 24px;
      a {
        display: inline-block;
      }
    }
  }
}
@media (min-width: 1200px) {
  .consult__s3 {
    margin-top: -139px;
  .consult__body {
    margin: 0;
    h4 {
      font-size: 28px;
      line-height: 140%;
      max-width: 750px;
      margin-bottom: 16px;
    }
  }
    input {
      background: #fff;
      height: 57px;
      font-size: 20px;
      line-height: 134.77%;
    }
    .block-button {
      .btn {
        height: 57px;
        font-size: 16px;
        line-height: 134.77%;
      }
    }
    .consult__pers__data {
      font-size: 16px;
      line-height: 160%;
      margin-top: 35px;
    }
  }
}