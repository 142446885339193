.open_menu {
  overflow: hidden;

  .mobile__menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 40px;
    padding-top: 100px;
    padding-right: 40px;
    z-index: 10;
    box-sizing: border-box;
    min-width: 320px;
    max-width: 320px;

    &:before {
      content: '';
      background: rgba(221, 234, 232, 0.6);
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .fixed__menu_d {
      display: block;
      padding: 0 40px;

      li {
        margin-bottom: 24px;

        a {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
        }

        &:hover {
          &:before {
            display: none;
          }

          a {
            color: $greenlight;
          }
        }
      }
    }

    .active {
      position: relative;

      &:before {
        display: none;
      }

      a {
        color: $greenlight;
      }
    }
  }

  .fixed__phone {
    z-index: 11;
    text-align: left;
    position: relative;
    padding-top: 25px;

    a {
      width: 100%;
      margin: 0 0 17px;
      text-align: center;

    }

    span {
      display: block;
      text-align: center;
      .icon_phone_d {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("/templates/img/icon/header_phone.svg") no-repeat center;
        background-size: contain;
        position: relative;
        top: 3px;
        margin-right: 8px;
        margin-left: 8px;
      }
    }

    img {
      display: none;
    }

    .fixed__btn {
      @include btn() {
        width: 100%;
        display: block;
        padding: 12px 0;
        height: 48px;
      }
    }

    &:before {
      content: '';
      height: 1px;
      width: 1000%;
      background: #D4E1DF;
      position: absolute;
      top: 0;
      left: -100%;
    }
  }


  .fullpage, footer {
    filter: blur(24px);
  }

  .fixed__hamburger {
    flex-basis: 22px;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    z-index: 11;

    span {
      width: 0;

      &:nth-child(2) {
        width: 100%;
        background-color: $greendark;
        transform: rotate(45deg);

        &:before {
          content: '';
          display: block;
          background-color: $greendark;
          height: 2px;
          border-radius: 3.33333px;
          width: 100%;
          transform: rotate(-90deg);
        }
      }

      &:nth-child(3) {
        margin-bottom: 0;
      }
    }

  }
}

@media (min-width: 740px) {
  .open_menu {
    .fixed__phone {
      right: 0;
    }
  }
}