.reviews__s5 {
  padding-top: 10px;
  padding-bottom: 35px;
  position: relative;

  .wrap {
    padding: 0;
    max-width: 100%;
  }

  .reviews__slider {
    padding: 0 0 40px;
    position: relative;
  }

  .reviews__item {
    padding: 24px 20px;
    background: #F7F8F9;
    border-radius: 8px;
    margin: 0 10px;
  }

  h2 {
    text-align: center;
    position: relative;

  }

  .item__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
      height: 40px;
      width: 40px;
      margin-right: 16px;
    }

    span {
      display: block;
      color: $greendark;
      font-weight: bold;
      font-size: px-to-rem(12px);
      line-height: 100%;
    }

    i {
      font-style: normal;
      color: #37B579;
      font-size: px-to-rem(10px);
      line-height: 134.77%;
    }
  }

  p {
    font-size: px-to-rem(10px);
    line-height: 160%;
  }

  .navig__reviews {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: -10%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 120px;
    z-index: 3;

    &.navig__reviews-desc {
      display: none;
    }

    span {
      width: 40px;
      height: 40px;
      border: 1px solid #8C9F9C;
      box-sizing: border-box;
      border-radius: 12px;
      cursor: pointer;
      position: relative;
      z-index: 3;

      &:after {
        content: "";
        position: absolute;
        background: url("/templates/img/icon/galka.svg") center no-repeat;
        background-size: cover;
        width: 6px;
        height: 10px;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }

      &:last-child {
        &:after {
          bottom: 50%;
          right: 50%;
          transform: translateY(-50%) translateX(-50%) rotate(180deg);
        }
      }
    }
  }

  .btn {
    @include btn {
      margin: auto;
      max-width: 260px;
      height: 48px;
    }
  }

  .reviews__form {
    margin-top: 48px;
  .wrap {
    min-width: 320px;
    max-width: 1360px;
    padding: 0 30px;
    margin: auto;

    p {
      text-align: center;
      padding: 0 30px;
      margin: 16px auto;

      span {
        text-decoration: underline;
        color: $green;
      }
    }
    h3 {
      text-align: center;
      font-size: 24px;
      line-height: 28px;
      margin:  40px 0 8px;
      color: $greendark;
    }
  }
    form {
        display: flex;
        flex-wrap: wrap;
      .form-group {
        flex: 0 0 auto;
        width: 100%;
      }
      .form__top, .form__bottom {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
      .form__bottom_b {
        width: 100%;
      }
    }
    &.visible {
      border-top: 1px solid #D4E1DF;
    .open__form {
      display: none;
    }
    }
  }

  input, textarea {
    background: rgba(212, 225, 223, .2);
    border-radius: 8px;
    border: none;
    font-size: px-to-rem(14px);
    line-height: 134.77%;
    color: $greendark;
    width: 100%;
    height: 48px;
    padding: 0 20px;
    box-sizing: border-box;
    margin-top: 16px;
  }

  textarea {
    height: 200px;
    padding: 20px;
    resize: none;
  }

  .block-button {
    .btn {
      @include btn {
        box-shadow: 0 9.15972px 15.2662px rgba(100, 203, 160, 0.55);
        margin-top: 24px;
        font-weight: bold;
        font-size: px-to-rem(14px);
        line-height: 134.77%;
      }
    }
  }
}

@media (min-width: 740px) {
  .reviews__s5 {
    padding-bottom: 54px;

    .navig__reviews {

      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 42%;
      left: initial;
      right: 50%;
      transform: translateY(0) translateX(50%);
      width: 80%;
      z-index: 3;

      span {
        width: 50px;
        height: 50px;
        border: 1px solid #8C9F9C;
        box-sizing: border-box;
        border-radius: 16px;
        cursor: pointer;
        position: relative;
        z-index: 3;

        &:after {
          content: "";
          position: absolute;
          background: url("/templates/img/icon/galka.svg") center no-repeat;
          background-size: cover;
          width: 6px;
          height: 10px;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }

        &:last-child {
          &:after {
            bottom: 50%;
            right: 50%;
            transform: translateY(-50%) translateX(-50%) rotate(180deg);
          }
        }
      }
    }

    .reviews__slider {
      max-width: 100%;
      margin: auto;
      padding: 13px 0 0;
    }

    .reviews__item {
      padding: 43px 48px 0;
      margin: 0 65px;
      box-sizing: border-box;
    }

    .item__header {
      margin-bottom: 32px;

      img {
        height: 56px;
        width: 56px;
        margin-right: 24px;
      }

      span {
        font-size: px-to-rem(18px);
      }

      i {
        font-size: px-to-rem(14px);
      }
    }

    p {
      font-size: px-to-rem(14px);
    }
  }
  .reviews__form {
    margin-top: 48px;
    &.visible {
      .wrap {
        height: initial;

      }
    }
    .wrap {
      min-width: 320px;
      max-width: 1360px;
      padding: 0 30px;
      margin: auto;
      height: 0;
      overflow: hidden;

      p {
        text-align: center;
        padding: 0 30px;
        margin: 16px auto;

        span {
          text-decoration: underline;
          color: $green;
        }
      }
      h3 {
        text-align: center;
        font-size: 24px;
        line-height: 28px;
        margin:  40px 0 8px;
      }
    }
    form {
      display: flex;
      flex-wrap: wrap;
      .form-group {
        flex: 0 0 auto;
        width: 100%;
        &.form-group_1 {
          width: calc(50% - 19px);//
        }
      }
      .form__top, .form__bottom {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    &.visible {
      border-top: 1px solid #D4E1DF;
      .open__form {
        display: none;
      }
    }
  }

  input, textarea {
    background: rgba(212, 225, 223, .2);
    border-radius: 8px;
    border: none;
    font-size: px-to-rem(14px);
    line-height: 134.77%;
    color: $greendark;
    width: 100%;
    height: 48px;
    padding: 0 20px;
    box-sizing: border-box;
    margin-top: 16px;
  }

  textarea {
    height: 200px;
    padding: 20px;
    resize: none;
  }

  .block-button {
    .btn {
      @include btn {
        box-shadow: 0 9.15972px 15.2662px rgba(100, 203, 160, 0.55);
        margin-top: 24px;
        font-weight: bold;
        font-size: px-to-rem(14px);
        line-height: 134.77%;
      }
    }
  }
}

@media (min-width: 1200px) {
  .reviews__s5 {
    padding-bottom: 87px;

    h2 {
      text-align: left;
      min-width: 320px;
      max-width: 1250px;
      padding: 0 30px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 80px auto 35px;
      font-size: 40px;
      line-height: 47px;
    }

    .reviews__slider {
      max-width: 100%;
      margin: auto;
      padding: 13px 0 0;
    }

    .reviews__item {
      padding: 43px 48px 0;
      margin: 0 25px;
      box-sizing: border-box;
      min-height: 270px;
    }

    .item__header {
      margin-bottom: 32px;

      img {
        height: 56px;
        width: 56px;
        margin-right: 24px;
      }

      span {
        font-size: px-to-rem(18px);
      }

      i {
        font-size: px-to-rem(14px);
      }
    }

    p {
      font-size: px-to-rem(14px);
    }

    //.navig__reviews {
    //  top: 66%;
    //  width: 80%;
    //  span {
    //    width: 50px;
    //    height: 50px;
    //    border-radius: 16px;
    //  }
    //}

    .navig__reviews {
      display: none;

      &.navig__reviews-desc {
        position: absolute;
        display: flex;
        top: 17px;
        right: 20px;
        transform: translateY(-50%) translateX(0);
        width: 120px;
        z-index: 3;

        span {
          width: 50px;
          height: 50px;
          border: 1px solid #8C9F9C;
          box-sizing: border-box;
          border-radius: 16px;
          cursor: pointer;
          position: relative;
          z-index: 3;

          &:after {
            content: "";
            position: absolute;
            background: url("/templates/img/icon/galka.svg") center no-repeat;
            background-size: cover;
            width: 6px;
            height: 10px;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
          }

          &:last-child {
            &:after {
              bottom: 50%;
              right: 50%;
              transform: translateY(-50%) translateX(-50%) rotate(180deg);
            }
          }
        }

        .prev {
          &:hover {
            background: #F7F8F9;
            border: none;
          }
        }

        .next {
          &:hover {
            background: #F7F8F9;
            border: none;
          }
        }
      }
    }
    .reviews__form {
      margin-top: 48px;
      &.visible {
        .wrap {
          height: initial;

        }
      }
      .wrap {
        min-width: 320px;
        max-width: 1360px;
        padding: 0 30px;
        margin: auto;
        height: 0;
        overflow: hidden;

        p {
          text-align: center;
          padding: 0 30px;
          margin: 16px auto;

          span {
            text-decoration: underline;
            color: $green;
          }
        }
        h3 {
          text-align: center;
          font-size: 24px;
          line-height: 28px;
          margin:  40px 0 8px;
        }
      }
      form {
        display: flex;
        flex-wrap: wrap;
        .form-group {
          flex: 0 0 auto;
          width: 100%;
          &.form-group_1 {
            width: 100%;
            margin-bottom: 32px;
            padding-right: 50px;
          }
        }
        .form__top {
          width: 27%;
          display: block;
          flex-wrap: wrap;
          justify-content: left;
          padding-right: 10px;
          box-sizing: border-box;
        }
        .form__bottom {
          width: 73%;
          display: flex;
          flex-wrap: wrap;
          justify-content: left;
        }
        .form__bottom_b {
          display: flex;
          justify-content: space-between;
          margin-top: 32px;
          .consult__pers__data{
            flex: 0 0 60%;
            width: 60%;
            padding: 0;
            text-align: left;
          }
          .block-button {
            flex: 0 0 40%;
            width: 40%;
            button {
              margin-right: 0;
              margin-top: 0;
              margin-bottom: 30px;
            }
          }
        }
      }
      &.visible {
        border-top: 1px solid #D4E1DF;
        .open__form {
          display: none;
        }
      }
    }
    textarea {
      height: 160px;
      padding: 20px;
      resize: none;
    }
    input {
      height: 56px;
    }
  }

}

@media (min-width: 1400px) {
  .reviews__s5 {
    padding-bottom: 54px;

    .slick-list {
      padding-left: 273px !important;

      .slick-slide {
        opacity: 0.15;

        &.slick-active {
          opacity: 1;
        }

      }
    }

    .reviews__slider {
      max-width: 1920px;
      margin: auto;
      padding: 13px 0 0;
    }

    .reviews__item {
      padding: 43px 48px 0;
      margin: 0 25px;
      box-sizing: border-box;
    }

    .item__header {
      margin-bottom: 32px;

      img {
        height: 56px;
        width: 56px;
        margin-right: 24px;
      }

      span {
        font-size: px-to-rem(18px);
      }

      i {
        font-size: px-to-rem(14px);
      }
    }

    p {
      font-size: px-to-rem(14px);
    }

    //.navig__reviews {
    //  top: 66%;
    //  width: 80%;
    //  span {
    //    width: 50px;
    //    height: 50px;
    //    border-radius: 16px;
    //  }
    //}
  }
}