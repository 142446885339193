#mCSB_1 {
  padding-bottom: 100px;
  box-sizing: content-box;
}


#hellopreloader_preload {
  display: block;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 320px;
  background: #f8f8f8 url("/templates/img/oval.svg") center center no-repeat;
  background-size: 108px;
  &:after {
    content: "";
    display: block;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-42%);
    background: url("/templates/img/icon_preload.png") center center no-repeat;
    background-size: 100px;
  }
}

.map {
  overflow: hidden;
  height: 538px;
  text-align: center;

  img {
    height: 100%;
  }
}

.footer__menu {
  color: $greendark;
  box-sizing: border-box;
  width: 100%;
  padding-top: 10px;
}

.footer__wrap {
  display: flex;
  align-items: center;
  position: relative;
}

.footer__hamburger {
  flex-basis: 22px;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;

  span {
    display: block;
    background-color: $greenlight;
    height: 2px;
    border-radius: 3.33333px;
    margin-bottom: 5px;
    width: 75%;

    &:nth-child(2) {
      width: 100%;
      background-color: $greendark;
    }

    &:nth-child(3) {
      margin-bottom: 0;
    }
  }

}

.footer__menu_d {
  display: none;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    flex: 0 0 auto;
    margin-right: 29px;
    position: relative;

    &:hover {
      &:before {
        content: "";
        position: absolute;
        bottom: -35px;
        height: 2px;
        width: 100%;
        background-color: $greenlight;
      }

      a {
        color: $greenlight;
      }
    }

    a {
      text-decoration: none;
      font-weight: 500;
      font-size: px-to-rem(16px);
      line-height: px-to-rem(19px);
    }

  }

  .active {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: -35px;
      height: 2px;
      width: 100%;
      background-color: $greenlight;
    }

    a {
      color: $greenlight;
    }
  }

}

.footer__logo {
  width: 48%;
  min-width: 140px;
  max-width: 215px;
  margin: auto;

  img {
    width: 100%;
  }
}

.footer__phone {
  text-align: right;
  display: none;

  a {
    display: block;
    text-decoration: none;
  }

  img {
    display: block;
    height: 20px;
    width: 20px;
  }

  span {
    display: none;
    font-weight: bold;
    font-size: px-to-rem(14px);
    line-height: px-to-rem(14px);
  }

}

.footer__btn {
  display: none;
}

@media (min-width: 740px) {
  footer {
    .fixed {
      padding-top: 15px;
    }
  }
  .footer__hamburger {
    &::before {
      content: "Меню";
      position: absolute;
      left: 40px;
      font-weight: 500;
      font-size: px-to-rem(12px);
      line-height: 134.77%;
    }
  }
  .footer__phone {
    position: absolute;
    right: 40px;

    a {
      color: $greendark;
    }

    img {
      display: none;
    }

    span {
      display: block;
      font-weight: bold;
      font-size: px-to-rem(14px);
      line-height: px-to-rem(14px);
      text-align: right;

      .icon_phone_d {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("/templates/img/icon/header_phone.svg") no-repeat center;
        background-size: contain;
        position: relative;
        top: 3px;
        margin-right: 8px;
        margin-left: 8px;
      }
    }

  }
  .footer__btn {
    background: none;
    display: inline-block;
    border: none;
    box-shadow: none;
    color: $greenlight;
    font-weight: 500;
    font-size: px-to-rem(12px);
    line-height: 134.77%;
    border-bottom: 1px dotted $greenlight;
    padding: 0;

    &:hover {
      margin: 0;
      cursor: pointer;
    }
  }
}

@media (min-width: 1260px) {
  .footer__menu {
    padding-top: 22px;
  }
  .footer__menu_d {
    display: flex;
  }
  .footer__hamburger {
    display: none;
  }
  .footer__logo {
    margin: 0 3% 0 0;
    max-width: 250px;
  }
  .footer__phone {
    right: 30px;
    display: block;

    span {
      font-weight: bold;
      font-size: px-to-rem(20px);
      line-height: px-to-rem(20px);

      .icon_phone_d {
        top: 5px;
        width: 24px;
        height: 24px;

      }
    }

  }
}