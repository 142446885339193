.title__m_spec {
  font-size: 24px;
  line-height: 28px;
  margin-top: 40px;
  margin-bottom: 24px;
  text-align: center;
}

.bloc__spec {
  display: flex;
  flex-wrap: wrap;
}

.left__spec {
  width: 100%;
  order: 2;

  h2 {
    display: none;
  }

  p {
    font-size: 12px;
    line-height: 160%;
  }

  span {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }

  .title__spec {
    font-weight: bold;
    text-align: center;
    display: block;
    margin-top: 24px;
  }

  .addres_spec {
    text-align: center;
    font-size: 10px;
    line-height: 160%;
    margin: 8px 0 24px;
  }

  .top__desc {
    p {
      &:last-child {
        max-width: 560px;
      }
    }
  }


}
.specialist__s4 {
  .btn {
    @include btn {
      padding-top: 16px;
      padding-bottom: 16px;
      margin-top: 24px;
      margin-bottom: 30px;
    }
  }
}
.right__spec {
  flex: 0 0 100%;
  min-width: calc(100% + 40px);
  order: 1;
  min-height: 200px;
  margin-left: -20px;

  .img-1 {
    height: 100%;
    width: 100%;
  }
}


@media (min-width: 740px) and (max-width: 960px) {
  .specialist__s4 {
    .btn {
      @include btn {
        font-size: px-to-rem(14px);
        margin: 40px auto;
        width: 257px;
      }
    }
    .block-button {
      margin: auto;
      width: 300px;
    }
  }
  .title__m_spec {
    margin-top: 56px;
    margin-bottom: 32px;
  }
  .top__desc {
    p {
      &:last-child {
        max-width: 560px;
        margin-bottom: 32px;
      }
    }
  }
  .left__spec {
    display: flex;
    flex-wrap: wrap;

    p {
      font-size: 14px;
      line-height: 160%;
    }

    span {
      font-size: 18px;
      line-height: 21px;
    }

    .addres_spec {
      display: none;
    }

    .title__spec {
      font-size: 18px;
      line-height: 140%;
      margin-top: 48px;
      margin-bottom: 32px;
    }

    .top__desc {
      flex: 0 0 100%;
      width: 100%;
    }

    .left__desc {
      flex: 0 0 50%;
      width: 50%;
    }

    .right__desc {
      flex: 0 0 50%;
      width: 50%;
    }
  }
  .right__spec {
    flex: 0 0 100%;
    min-width: calc(100% + 80px);
    order: 1;
    min-height: 420px;
    margin-left: -40px;

    .img-1 {
      height: 100%;
      width: 100%;
    }
  }
}

@media (min-width: 960px) {
  .specialist__s4 {
    max-height: 768px;
    overflow: hidden;

    .title__m_spec {
      display: none;
    }

    .block-button {
      margin: 0;
      max-width: 300px;
    }
  }
  .bloc__spec {
    display: flex;
    flex-wrap: nowrap;
  }
  .left__spec {
    flex: 0 0 34%;
    width: 34%;
    order: 1;

    h2 {
      display: block;
      font-size: 40px;
      line-height: 47px;
      margin-top: 93px;
    }

    p {
      font-size: 16px;
      line-height: 160%;
      margin-top: 8px;
    }

    span {
      font-size: 16px;
      line-height: 19px;
    }

    .addres_spec {
      text-align: left;
      font-size: 16px;
      line-height: 160%;
    }

    .title__spec {
      font-size: 20px;
      line-height: 140%;
      margin-top: 66px;
      margin-bottom: 11px;
      text-align: left;
    }

    .right__desc {
      margin-top: 27px;
    }
  }
  .right__spec {
    flex: 0 0 100%;
    width: 100%;
    min-width: initial;
    order: 2;
    margin: 0;
  }
}
@media (min-width: 1930px) {
  .specialist__s4 {
    .wrap {
      overflow: hidden;
    }
  }
}