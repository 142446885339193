@charset "UTF-8";

/**
 * Основные стили
 **/

html {
  font-size: 16px;
  color: $greendark;
  overflow-x: hidden;
}

body {
  font-family: 'TT Norms', sans-serif;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.container {
  @include min-max(320px, 100%, 0);
}

.wrap {
  @include min-max(320px, 1300px, 20px);
}

a {
  color: $greendark;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    padding: 0;
  }
}

button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:hover {
    padding: 0;
  }
}

h2 {
  font-weight: bold;
  font-size: px-to-rem(24px);
  line-height: px-to-rem(28px);
}

@media (min-width: 740px) {
  .wrap {
    @include min-max(320px, 1360px, 40px);
  }
}

@media (min-width: 1260px) {
  .wrap {
    @include min-max(320px, 1360px, 30px);
  }
}

* {
  outline: 0 !important;
}
// Remove border and padding in Firefox
::-moz-focus-outer, ::-moz-focus-inner {
  border: 0;
  padding: 0;
}