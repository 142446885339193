.personal__s4 {
  padding: 47px 0;
  .block__button {
    display: none;
  }
  h4 {
    font-weight: bold;
    font-size: px-to-rem(24px);
    line-height: px-to-rem(28px);
    text-align: center;
    margin: 0;
  }
  background: #F7F8F9;
  .personal__slider {
    position: relative;
    .navig__pers {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 105px;
      transform: translateY(-50%);
      width: 100%;
      z-index: 3;

      span {
        width: 40px;
        height: 40px;
        border: 1px solid #8C9F9C;
        box-sizing: border-box;
        border-radius: 12px;
        cursor: pointer;
        position: relative;
        z-index: 3;

        &:after {
          content: "";
          position: absolute;
          background: url("/templates/img/icon/galka.svg") center no-repeat;
          background-size: cover;
          width: 6px;
          height: 10px;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }

        &:last-child {
          &:after {
            bottom: 50%;
            right: 50%;
            transform: translateY(-50%) translateX(-50%) rotate(180deg);
          }
        }
      }
    }
  }

  .img__pers {
    height: 188px;
    margin: 20px 0 7px;
    position: relative;
    overflow: hidden;
    img {
      width: 60%;
      max-width: 200px;
      margin: auto;
      display: block;
    }

  }

  .name__pers {
    font-weight: bold;
    display: block;
    font-size: px-to-rem(12px);
    line-height: 140%;
    text-align: center;
  }

  .address__pers {
    text-align: center;
    font-weight: normal;
    font-size: px-to-rem(10px);
    line-height: 160%;
    display: block;
    margin-top: 8px;
  }

  .description__pers {
    font-weight: normal;
    font-size: px-to-rem(12px);
    line-height: 160%;
    margin-top: 26px;

    p {
      margin: 0;
    }

    span {
      margin: 16px 0 8px;
      font-weight: bold;
      line-height: px-to-rem(14px);
      display: block;
    }
  }

  .btn {
    @include btn {
      padding-top: 16px;
      padding-bottom: 16px;
      margin-top: 24px;
    }
  }

  .left__personal {
    display: none;
  }
}

@media (min-width: 740px) {
  .personal__s4 {
    padding: 56px 0 0;
    .item__presonal {
      display: flex;
      flex: 0 0 100%;
      padding-left: 86px;

      .img__pers {
        height: 566px;
        max-width: 57%;
        margin: 20px 0 0 0;
        flex: 0 0 57%;
        background-size: 100%;
        order: 2;


        img {
          height: auto;
          width: auto;
          max-width: 100%;
          margin: 30px auto 0;
          position: absolute;
          bottom: 0;
        }
      }

      .right__presonal {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        max-width: 43%;
        flex: 0 0 43%;
      }

      .name__pers {
        font-size: px-to-rem(18px);
        line-height: 140%;
        text-align: left;
      }

      .address__pers {
        font-size: px-to-rem(14px);
        text-align: left;
        display: none;
      }

      .description__pers {
        font-size: px-to-rem(14px);
        margin-top: 24px;

        span {
          margin: 32px 0 8px;
          font-weight: bold;
          font-size: 18px;
          line-height: px-to-rem(21px);
          display: block;
        }

        .btn {
          @include btn {
            font-size: px-to-rem(14px);
            margin-top: 40px;
          }
        }
      }
    }

    .personal__slider {
      position: relative;
      background: url("/templates/img/bg_personal_desc.png") center bottom no-repeat;
      background-size: 140%;
      .navig__pers {
        top: 50%;
        span {
          width: 50px;
          height: 50px;
          border-radius: 16px;
        }
      }

    }
  }
}

@media (min-width: 1200px) {
  .personal__s4 {
    box-sizing: border-box;
    padding-top: 6px;
    .data__pres {
      display: none;
    }
    h4 {

      font-size: px-to-rem(40px);
      line-height: px-to-rem(47px);
      margin: 0;
      text-align: left;
      padding-left: 20px;
      position: relative;
      top: 81px;
      z-index: 2;

    }
    .left__personal-slider{
      margin-top: 100px;
      height: 590px;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        width: 90%;
        height: 124px;
        background: linear-gradient(360deg,#f7f8f9 0,#f7f8f9 70.33%,rgba(242,246,244,0) 100%);
      }
      .slick-list {
        margin: 0 0 0 -60px;
        padding: 20px 0 0 62px;
      }
    }
    .slider__personal {
      max-width: 60%;
      flex: 0 0 60%;
    }
    .personal__slider {
      display: flex;
      max-width: 1200px;
      margin: auto;
      .left__personal {
        display: block;
        max-width: 40%;
        flex: 0 0 40%;
        overflow: hidden;
        padding-bottom: -2px;

        .card__personal {
          display: flex;
          align-items: center;
          padding: 12px 15px;
          max-width: 450px;
          box-sizing: content-box;
          margin-bottom: 17px;
          .card__right {
            padding-left: 30px;
            width: 100%;
            span {
              font-weight: bold;
              font-size: 20px;
              line-height: 140%;
              max-width: 90%;
              display: inline-block;
            }
            p {
              margin-top: 11px;
              margin-bottom: 6px;
            }
          }
          &:hover {
          &.slick-current {
            background: url("/templates/img/bg_slider_active.png") no-repeat;
            background-size: contain;
            position: relative;
            border: 8px;

            &:after {
              content: "";
              z-index: -1;
              position: absolute;
              width: 85%;
              height: 100%;
              left: 0;
              box-shadow: 17px 8px 26px 8px rgba(101, 80, 68, .1);
              border: 8px;
              border-bottom-right-radius: 50px;
              border-top-right-radius: 50px;
            }
          }
          }
          &.slick-current {
            background: url("/templates/img/bg_slider_active.png") no-repeat;
            background-size: contain;
            position: relative;
            border: 8px;

            &:after {
              content: "";
              z-index: -1;
              position: absolute;
              width: 85%;
              height: 100%;
              left: 0;
              box-shadow: 17px 8px 26px 8px rgba(101, 80, 68, .1);
              border: 8px;
              border-bottom-right-radius: 50px;
              border-top-right-radius: 50px;
            }
          }
          &:hover {
              background: url("/templates/img/bg_slider_hover.png") no-repeat;
              background-size: 95% 100%;
          }


          .img__card {
            width: 120px;
            height: 88px;
            background: #D4E1DF;
            border-radius: 8px;
            overflow: hidden;
            img {
              width: 100%;
              padding-top: 5px;

            }
          }

        }
      }
      .item__presonal {
        padding: 0;
        .img__pers{
          order: 0;
          height: 694px;
        }
        .description__pers {
          padding-left: 12px;
          margin-top: 0;
          p {
            font-size: px-to-rem(16px);
            line-height: 160%;
          }
          span {
            font-size: px-to-rem(16px);
          }
          .btn {
            font-size: 16px;
          }
        }
      }

    }
    .personal__slider {
      .navig__pers {
        display: none;

      }

    }
    .block__button {
      display: block;
      height: 120px;
      margin-top: -120px;
      position: relative;
      z-index: 3;

      .up__down {
        width: 120px;
        display: flex;
        position: absolute;
        bottom: 17px;
        left: 47%;
        transform: translateX(-50%);
        justify-content: space-between;
        z-index: 2;

        .stop {
          opacity: 0.5;
          cursor: default;
        }

        button {
          width: 50px;
          height: 50px;
          border: 1px solid #8C9F9C;
          box-sizing: border-box;
          border-radius: 18px;
          cursor: pointer;
          position: relative;
          z-index: 3;

          &:after {
            content: "";
            position: absolute;
            background: url("/templates/img/icon/galka.svg") center no-repeat;
            background-size: cover;
            width: 6px;
            height: 10px;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%) rotate(90deg);


          }

          &:last-child {
            &:after {
              bottom: 50%;
              right: 50%;
              transform: translateY(-50%) translateX(-50%) rotate(-90deg);
            }
          }
        }
        .scroll__up_p {
          &:hover{
          background: white;
            border: none;
            box-shadow: 0 10px 30px rgba(101, 80, 68, 0.1);
        }
        }
        .scroll__down_p {
          &:hover{
            background: white;
            border: none;
            box-shadow: 0 10px 30px rgba(101, 80, 68, 0.1);
          }
        }
      }
    }
  }
}