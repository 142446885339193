.spasibo_index {
  height: 100%;
  body {
    height: 100%;
    .container {
      height: 88%;
      .header {
        height: 100%;
      }
    }
    .fullpage {
      height: 70%;
    }
  }
}


.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  color: $greendark;
  box-sizing: border-box;
  width: 100%;
  padding-top: 10px;
  transition: all .3s ease;
}
.fixed {
  &.out {
    transform: translateY(-100%);
}
}
.fixed__wrap {
  display: flex;
  align-items: center;
  position: relative;
}

.fixed__hamburger {
  flex-basis: 22px;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  z-index: 11;

  span {
    display: block;
    background-color: $greenlight;
    height: 2px;
    border-radius: 3.33333px;
    margin-bottom: 5px;
    width: 75%;

    &:nth-child(2) {
      width: 100%;
      background-color: $greendark;
    }

    &:nth-child(3) {
      margin-bottom: 0;
    }
  }

}

.fixed__menu_d {
  display: none;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    flex: 0 0 auto;
    margin-right: 29px;
    position: relative;
    &:hover {
      &:before {
        content: "";
        position: absolute;
        top: -40px;
        height: 2px;
        width: 100%;
        background-color: $greenlight;
      }
      a {
        color: $greenlight;
      }
  }
    a {
      text-decoration: none;
      font-weight: 500;
      font-size: px-to-rem(16px);
      line-height: px-to-rem(19px);
    }

  }
  .active {
    position: relative;
    color: $greenlight;
    &:before {
      content: "";
      position: absolute;
      top: -40px;
      height: 2px;
      width: 100%;
      background-color: $greenlight;
    }
  }

}

.fixed__logo {
  width: 48%;
  min-width: 140px;
  max-width: 215px;
  margin: auto;
  z-index: 11;

  img {
    width: 100%;
  }
}

.fixed__phone {
text-align: right;
  a {
    display: inline-block;
    text-decoration: none;
    margin-right: 20px;
  }

  img {
    display: block;
    height: 20px;
    width: 20px;
  }

  span {
    display: none;
    font-weight: bold;
    font-size: px-to-rem(14px);
    line-height: px-to-rem(14px);
  }

}

.fixed__btn {
  display: none;
  background: none;
  border: none;
  box-shadow: none;
  color: $greenlight;
  font-weight: 500;
  font-size: px-to-rem(12px);
  line-height: 134.77%;
  border-bottom: 1px dotted $greenlight;
  padding: 0;

  &:hover {
    margin: 0;
    cursor: pointer;
  }
}

@media (min-width: 740px) {
  .fixed {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .fixed__hamburger {
    &::before {
      content: "Меню";
      position: absolute;
      left: 40px;
      font-weight: 500;
      font-size: px-to-rem(12px);
      line-height: 134.77%;
    }
  }
  .fixed__phone {
    position: absolute;
    right: 40px;
    top: 0;
    max-width: 200px;
    a {
      color: $greendark;
      margin: 0;
    }

    img {
      display: none;
    }

    span {
      display: block;
      font-weight: bold;
      font-size: px-to-rem(14px);
      line-height: px-to-rem(14px);
      text-align: right;

      .icon_phone_d {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("/templates/img/icon/header_phone.svg") no-repeat center;
        background-size: contain;
        position: relative;
        top: 3px;
        margin-right: 8px;
        margin-left: 8px;
      }
    }

  }
  .fixed__btn {
    display: inline-block;
  }
}

@media (min-width: 1200px) {
  .fixed {
    padding-top: 22px;
    padding-bottom: 22px;
  }
  .fixed__menu_d {
    display: flex;
  }
  .fixed__hamburger {
    display: none;
  }
  .fixed__logo {
    margin: 0 3% 0 0;
    max-width: 250px;
  }
  .fixed__phone {
    right: 30px;
    max-width: 250px;
    span {
      font-weight: bold;
      font-size: px-to-rem(20px);
      line-height: px-to-rem(20px);
      .icon_phone_d {
        top: 5px;
        width: 24px;
        height: 24px;

      }
    }

  }
}