.consult__s8 {
  background: url("/templates/img/bg_cons_s8_m.jpg") center no-repeat;
  background-size: cover;
  padding: 40px 0;

  .block__form {

    background: none;
    border-radius: 16px;
    padding: 30px 20px;
    color: white;
    width: 100%;
    max-width: 344px;
    margin: auto;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 10px 30px rgba(68, 101, 91, 0.16);

    &:before {
      content: "";
      border-radius: 16px;
      background: linear-gradient(92.34deg, #3CC684 21.38%, #00B294 162.86%);
      opacity: 0.7;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
    }

    h4 {
      font-weight: bold;
      font-size: px-to-rem(18px);
      line-height: px-to-rem(21px);
      text-align: center;
      margin: 0;
      z-index: 1;
      position: relative;
      margin-bottom: 10px;
    }

    input {
      position: relative;
      z-index: 1;
      background: #FFFFFF;
      border-radius: 8px;
      border: none;
      font-size: px-to-rem(14px);
      line-height: 134.77%;
      color: $greendark;
      width: 100%;
      height: 48px;
      padding: 0 20px;
      box-sizing: border-box;
      margin-top: 16px;
    }

    .block-button {
      z-index: 1;
      position: relative;

      .btn {
        @include btn {
          background: #425351;
          box-shadow: 0 9.15972px 15.2662px rgba(100, 203, 160, 0.55);
          margin-top: 24px;
          font-weight: bold;
          font-size: px-to-rem(14px);
          line-height: 134.77%;
        }

        &:hover {
          background-color: white;
          color: #425351;
          box-shadow: 0 12px 20px rgba(52, 132, 98, 0.55);
        }
      }
    }

    .consult__pers__data {
      z-index: 1;
      position: relative;
      font-weight: normal;
      font-size: px-to-rem(10px);
      line-height: 160%;
      text-align: center;
      margin: 16px 0 0;

      a {
        display: block;
        text-decoration: underline;
        color: white;
        font-weight: 500;
      }
    }
  }

}
.index_body {
  .consult__s8 {
    background: url("/templates/img/bg_cons_s8_m_index.jpg") center no-repeat;
  }
}

.ginek_body {
  .consult__s8 {
    background: url("/templates/img/bg_cons_s8_m_ginekolog.jpg") center no-repeat;
  }
  .price__s2 {
    .price__right {
      &:after {
        background: url("/templates/img/paporotnik_ginekolog.png") no-repeat;
        right: 0;
        background-size: cover;
        display: block;
        width: 177px;
        bottom: 0;
        height: 150px;
        z-index: 0;
      }
    }
  }
}

.uzi_body {
  .consult__s8 {
    background: url("/templates/img/bg_cons_s8_m_uzi.jpg") center no-repeat;
  }
}

.analiz_body {
  .consult__s8 {
    background: url("/templates/img/bg_cons_s8_m_analiz.jpg") center no-repeat;
  }
}
@media (min-width: 560px) {
  .consult__s8 {
    background: url("/templates/img/bg_cons_s8_p.jpg") left no-repeat;
    background-size: contain;
    padding: 80px 0;
    position: relative;

    &:after {
      content: "";
      background: linear-gradient(123.36deg, #ECF2F2 50.04%, #EAEAE8 98.77%);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
    }

    .block__form {
      margin-left: auto;
      margin-right: 0;
      padding: 40px 43px;

      h4 {
        font-size: px-to-rem(24px);
        line-height: 140%;
        text-align: left;
        margin-bottom: 15px;
      }

      .consult__pers__data {
        font-size: px-to-rem(14px);
        text-align: left;
        margin-top: 24px;

        a {
          display: inline-block;
        }
      }
    }

  }
  .index_body {
    .consult__s8 {
      background: url("/templates/img/bg_cons_s8_p_index.jpg") center no-repeat;
    }
  }

  .ginek_body {
    .consult__s8 {
      background: url("/templates/img/bg_cons_s8_p_ginekolog.jpg") center no-repeat;
    }
  }

  .uzi_body {
    .consult__s8 {
      background: url("/templates/img/bg_cons_s8_p_uzi.jpg") center no-repeat;
    }
  }

  .analiz_body {
    .consult__s8 {
      background: url("/templates/img/bg_cons_s8_p_analiz.jpg") center no-repeat;
    }
  }
}

@media (min-width: 960px) {
  .consult__s8 {
    background: url("/templates/img/bg_cons_s8_d.jpg") center no-repeat;
    background-size: cover;
    padding: 103px 0;

    .block__form {
      margin-left: auto;
      margin-right: 0;
      padding: 40px 43px;
      max-width: 400px;

      h4 {
        font-size: px-to-rem(28px);
        line-height: px-to-rem(33px);
        text-align: left;
      }

      input {
        height: 56px;
        font-size: px-to-rem(20px);
      }

      .block-button {
        .btn {
          @include btn {
            background: #425351;
            box-shadow: 0 9.15972px 15.2662px rgba(100, 203, 160, 0.55);
            margin-top: 32px;
            font-weight: bold;
            font-size: px-to-rem(16px);
            height: 56px;
          }
        }
      }

      .consult__pers__data {
        font-size: px-to-rem(14px);
        text-align: center;
        margin-top: 24px;

        a {
          display: inline-block;
        }
      }
    }
  }
  .index_body {
    .consult__s8 {
      background: url("/templates/img/bg_cons_s8_d_index.jpg") center no-repeat;
    }
  }

  .ginek_body {
    .consult__s8 {
      background: url("/templates/img/bg_cons_s8_d_ginekolog.jpg") center no-repeat;
    }
    .price__s2 {
      .price__right {
        &:after {
          background: url("/templates/img/paporotnik_ginekolog.png") no-repeat;
          right: -55%;
          width: 433px;
          bottom: -1px;
          height: 461px;
        }
      }
    }
  }

  .uzi_body {
    .consult__s8 {
      background: url("/templates/img/bg_cons_s8_d_uzi.jpg") center no-repeat;
    }
  }

  .analiz_body {
    .consult__s8 {
      background: url("/templates/img/bg_cons_s8_d_analiz.jpg") center no-repeat;
      background-size: cover;
    }
  }
}