.contacts__s9 {
  padding: 45px 0 115px;
  position: relative;

  h2 {
    text-align: center;
    margin: 0 0 24px;

  }

  .item__contacts {
    position: relative;
    flex-wrap: wrap;
    display: flex;
    flex: 0 0 100%;
    box-sizing: border-box;

    h4 {
      text-align: center;
      max-width: 100%;
      flex: 0 0 100%;
      margin: 0 0 18px;
      font-weight: bold;
      font-size: px-to-rem(14px);
      line-height: px-to-rem(17px);
    }

    span {
      display: block;
      max-width: 50%;
      flex: 0 0 50%;
      text-align: center;
      font-size: px-to-rem(12px);
      line-height: px-to-rem(14px);
      position: relative;
    }

    .addres {
      max-width: 100%;
      flex: 0 0 100%;

    }

    .phone {
      font-weight: bold;
      margin-top: 24px;
      padding-top: 40px;

      &:after {
        content: "";
        background: url("/templates/img/icon/phone__contacts.svg") center  no-repeat;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .time {
      margin-top: 24px;
      padding-top: 40px;

      &:after {
        content: "";
        background: url("/templates/img/icon/time__contacts.svg") center no-repeat;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .navig__contacts {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 94%;
    left: 50%;
    transform: translateY(-150%) translateX(-50%);
    width: 120px;
    z-index: 3;

    span {
      width: 40px;
      height: 40px;
      border: 1px solid #8C9F9C;
      box-sizing: border-box;
      border-radius: 12px;
      cursor: pointer;
      position: relative;
      z-index: 3;

      &:after {
        content: "";
        position: absolute;
        background: url("/templates/img/icon/galka.svg") center no-repeat;
        background-size: cover;
        width: 6px;
        height: 10px;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }

      &:last-child {
        &:after {
          bottom: 50%;
          right: 50%;
          transform: translateY(-50%) translateX(-50%) rotate(180deg);
        }
      }
    }
  }
}


@media (min-width: 594px) {
  .contacts__s9 {
    padding: 56px 0 80px;

    h2 {
      text-align: center;
      margin: 0 0 43px;

    }

    .item__contacts {
      h4 {
        text-align: left;
      }

      span {
      text-align: left;

      }

      .addres {
      }

      .phone {
        padding-left: 34px;
        font-weight: bold;
        margin-top: 24px;
        padding-top: 0;

        &:after {
          left: 0;
          width: 18px;
          height: 18px;
          transform: translateX(0);
          background-size: contain;
        }
      }

      .time {
        padding-left: 34px;
        margin-top: 24px;
        padding-top: 0;

        &:after {
          width: 18px;
          height: 18px;
          left: 0;
          transform: translateX(0);
          background-size: contain;
        }
      }
    }
  }

}

@media (min-width: 760px) {
  .contacts__s9 {
    padding: 58px 0;
    .slick-slide {
      padding: 0 0px;
      box-sizing: border-box;
    }
    h2 {
      text-align: center;
      margin: 0 0 45px;

    }

    .item__contacts {
      margin: 0 20px;

      :last-child {
        margin-right: 0;
      }

      h4 {
        margin: 0 0 18px;
        font-weight: bold;
        font-size: px-to-rem(18px);
        line-height: px-to-rem(21px);
        text-align: left;
      }

      span {
        display: block;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(17px);
        text-align: left;
        max-width: 100%;
        flex: 0 0 100%;


      }

      .addres {
        font-size: px-to-rem(16px);
        line-height: px-to-rem(19px);
      }

      .phone {
        padding-left: 34px;
        font-weight: bold;
        margin-top: 24px;
        padding-top: 0;

        &:after {
          left: 0;
          transform: translateX(0);
          width: 18px;
          height: 18px;
          background-size: contain;
        }
      }

      .time {
        padding-left: 34px;
        margin-top: 24px;
        padding-top: 0;

        &:after {
          left: 0;
          transform: translateX(0);
          width: 18px;
          height: 18px;
          background-size: contain;
        }
      }
    }
    .navig__contacts {
      display: none;
    }
  }
}
@media (min-width: 1260px) {
  .contacts__s9 {
    .slick-slide {
    }
    h2 {
      font-size: px-to-rem(40px);
      line-height: px-to-rem(47px);
    }

    .item__contacts {
      margin-right: 40px;

      :last-child {
        margin-right: 0;
      }

      h4 {
        font-size: px-to-rem(24px);
        line-height: px-to-rem(28px);
      }

      span {
        font-size: px-to-rem(16px);
        line-height: px-to-rem(19px);
      }

      .addres {
        font-size: px-to-rem(16px);
        line-height: px-to-rem(19px);
      }

      .phone {
        padding-left: 34px;
        font-weight: bold;
        margin-top: 24px;
        padding-top: 0;

        &:after {
          left: 0;
          transform: translateX(0);
          width: 18px;
          height: 18px;
          background-size: contain;
        }
      }

      .time {
        padding-left: 34px;
        margin-top: 24px;
        padding-top: 0;

        &:after {
          left: 0;
          transform: translateX(0);
          width: 18px;
          height: 18px;
          background-size: contain;
        }
      }
    }
  }
}