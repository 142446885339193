.compensate-for-scrollbar {
  margin: 0 !important;
}
.zoom__img:hover {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-60%);
    background: url("/templates/img/icon/zoom.png") no-repeat;
    background-size: contain;
    width: 80px;
    height: 80px;
  }
}
.gallery__s7 {
  .wrap {
    padding: 0;
    max-width: 100%;
  }
  padding: 40px 0 88px;
  position: relative;
  h2{
    text-align: center;
    min-width: 320px;
    max-width: 1250px;
    padding: 0 26px;
    margin: 22px auto 48px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .slider__item {
    height: 260px;
    img {
      height: 100%;
    }
  }
  .navig__gallery {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateY(-150%) translateX(-50%);
    width: 120px;
    z-index: 3;

    span {
      width: 40px;
      height: 40px;
      border: 1px solid #8C9F9C;
      box-sizing: border-box;
      border-radius: 12px;
      cursor: pointer;
      position: relative;
      z-index: 3;

      &:after {
        content: "";
        position: absolute;
        background: url("/templates/img/icon/galka.svg") center no-repeat;
        background-size: cover;
        width: 6px;
        height: 10px;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }

      &:last-child {
        &:after {
          bottom: 50%;
          right: 50%;
          transform: translateY(-50%) translateX(-50%) rotate(180deg);
        }
      }
    }
  }
}

@media (min-width: 740px) {
  .gallery__s7 {
    padding: 66px 0 57px;
    position: relative;
    .slider__gallery {
      margin-left: 3%;
    }
    .slider__item {
      height: 300px;
    }
    h2 {
      text-align: left;
      margin: 0 0 40px;
    }
    .navig__gallery {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 56px;
      left: initial;
      right: 43px;
      transform: translateY(0) translateX(0);
      width: 120px;
      z-index: 3;

      span {
        width: 50px;
        height: 50px;
        border: 1px solid #8C9F9C;
        box-sizing: border-box;
        border-radius: 16px;
        cursor: pointer;
        position: relative;
        z-index: 3;

        &:after {
          content: "";
          position: absolute;
          background: url("/templates/img/icon/galka.svg") center no-repeat;
          background-size: cover;
          width: 6px;
          height: 10px;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }

        &:last-child {
          &:after {
            bottom: 50%;
            right: 50%;
            transform: translateY(-50%) translateX(-50%) rotate(180deg);
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .gallery__s7 {
    padding: 66px 0 57px;
    position: relative;
    .slider__gallery {
      margin-left: 10%;
    }
    .slider__item {
      height: 500px;
    }
    h2 {
      text-align: left;
      min-width: 320px;
      max-width: 1250px;
      padding: 0;
      margin: 22px auto 48px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 40px;
      line-height: 47px;
    }
    .navig__gallery {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 56px;
      left: initial;
      right: 43px;
      transform: translateY(0) translateX(0);
      width: 120px;
      z-index: 3;

      span {
        width: 50px;
        height: 50px;
        border: 1px solid #8C9F9C;
        box-sizing: border-box;
        border-radius: 16px;
        cursor: pointer;
        position: relative;
        z-index: 3;

        &:after {
          content: "";
          position: absolute;
          background: url("/templates/img/icon/galka.svg") center no-repeat;
          background-size: cover;
          width: 6px;
          height: 10px;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }

        &:last-child {
          &:after {
            bottom: 50%;
            right: 50%;
            transform: translateY(-50%) translateX(-50%) rotate(180deg);
          }
        }
      }
    }
  }
}