.price__wrap {
  max-width: 1200px;
  margin: auto;

  #scroll__spoiler_one, #scroll__spoiler_1, #scroll__spoiler_2, #scroll__spoiler_3 {
    max-height: 300px;
    overflow: hidden;
  }
}

.price__s2 {
  padding-top: 32px;
  padding-bottom: 100px;
  background: radial-gradient(92.26% 159.1% at 36.82% 0%, rgba(224, 224, 224, 0) 0%, #E0E0E0 100%);
  overflow: hidden;

  .slick-track {
    padding-bottom: 35px;
  }

  .price__tabs {
    text-align: center;
    padding-top: 6px;
    padding-bottom: 40px;

    ul {
      display: flex;
      flex-wrap: nowrap;
      font-weight: 500;
      font-size: px-to-rem(12px);
      line-height: 134.77%;

      li {
        white-space: nowrap;
        padding: 12px 12px;
        cursor: pointer;
        height: 48px;
        box-sizing: border-box;

        &.slick-current {
          box-shadow: 0 10px 30px rgba(101, 80, 68, 0.1);
          background-color: white;
          position: relative;
          border-radius: 8px;
          font-weight: 500;

          &:before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 20px 0 20px;
            border-color: #ffffff transparent transparent transparent;
            position: absolute;
            bottom: -12px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  h2 {
    text-align: center;
  }

  .scroll__spoiler {
    max-height: 400px;
    overflow: hidden;
    width: 100%;
    position: relative;
    z-index: 2;

    //&:before {
    //  display: block;
    //  content: "";
    //  position: absolute;
    //  bottom: 0;
    //  left: 0;
    //  height: 100px;
    //  background: linear-gradient(180deg, #F1F5F3 0%, #F2F6F4 58.33%, rgba(242, 246, 244, 0) 100%);
    //  transform: matrix(1, 0, 0, -1, 0, 0);
    //  width: 100%;
    //  z-index: 10;
    //}
  }

  .price__right {
    position: relative;
    top: -55px;

    &:before {
      content: "";
      background: linear-gradient(to top, rgba(212, 225, 223, 0.4) 0%, rgba(212, 225, 223, 1) 50%, rgba(212, 225, 223, 0.4) 100%);
      opacity: 0.4;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      background: url("/templates/img/paporotnik.png");
      background-size: cover;
      display: block;
      width: 120px;
      bottom: -46px;
      height: 150px;
      z-index: 1;
    }
  }

  .price__block {
    position: relative;

    [class*="price__block-item"] {
      z-index: 1;
      position: relative;
      border-bottom: 0.416667px solid #D4E1DF;
      font-weight: normal;
      font-size: px-to-rem(12px);
      line-height: px-to-rem(14px);
      padding: 9px 0;
      color: #000000;

      span {
        width: 80%;
        display: inline-block;

        &:last-child {
          position: absolute;
          right: 0;
          font-weight: 700;
          width: 20%;
          text-align: right;
          color: #333333;
        }
      }
    }
  }

  .spoiler__span {
    position: relative;
    width: calc(100% - 30px) !important;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      right: -30px;
      top: -5px;
      background: url("/templates/img/icon/galka_life.svg") no-repeat;
      width: 24px;
      height: 24px;
      transform: rotateX(180deg);
    }

    &.collapsed {
      &:after {
        transform: rotateX(0);
      }
    }
  }

  .spoiler-block {
    display: none;
    overflow: hidden;

    [class*="price__block-item"] {
      padding: 0 0 0 16px;
      border-bottom: none;
      margin-right: 0;

      .row {
        padding: 9px 0;
      }
    }

    [class*="price__block-item"].pretitle_spoiler {
      font-weight: bold;
      border-bottom: 0.4px solid #D4E1DF;
      padding: 9px 0;
      width: 100%;

    }
  }

  .block__button {
    height: 120px;
    margin-top: -40px;
    position: relative;
    z-index: 1;


    .up__down {
      width: 120px;
      display: flex;
      position: absolute;
      bottom: 26px;
      left: 50%;
      transform: translateX(-50%);
      justify-content: space-between;
      z-index: 2;

      .stop {
        opacity: 0.5;
        cursor: default;
      }

      button {
        width: 40px;
        height: 40px;
        border: 1px solid #8C9F9C;
        box-sizing: border-box;
        border-radius: 12px;
        cursor: pointer;
        position: relative;
        z-index: 3;

        &:after {
          content: "";
          position: absolute;
          background: url("/templates/img/icon/galka.svg") center no-repeat;
          background-size: cover;
          width: 6px;
          height: 10px;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%) rotate(90deg);


        }

        &:last-child {
          &:after {
            bottom: 50%;
            right: 50%;
            transform: translateY(-50%) translateX(-50%) rotate(-90deg);
          }
        }
      }

      #scroll__up {
        &:hover {
          background: white;
          border: none;
          box-shadow: 0 10px 30px rgba(101, 80, 68, 0.1);
        }
      }

      #scroll__down {
        &:hover {
          background: white;
          border: none;
          box-shadow: 0 10px 30px rgba(101, 80, 68, 0.1);
        }
      }
    }
  }

  .block__button-header {
    display: none;
  }

  &.price__s2_analiz {
    .price__block {
      [class*="price__block-item"] {
        display: flex;

        span {
          width: 25%;
          flex: 0 0 25%;
          font-weight: 700;

          &:first-child {
            flex: 0 0 50%;
            width: 50%;
            padding-left: 0;
            font-weight: 400;
          }

          &:last-child {
            font-weight: 700;
            text-align: right;
            color: #333333;
            padding-left: 0;
          }

          &:nth-child(2) {
            padding-left: 26px;
          }
        }

        &.spoiler {
          flex-wrap: wrap;

          .spoiler-block {
            width: 100%;
            padding-left: 16px;

            span {
              &:nth-child(2) {
                padding-left: 10px;
              }
            }
          }
        }
      }
    }

    .title__price {
      display: flex;

      span {
        display: inline-block;
        flex: 0 0 25%;
        width: 25%;
        font-size: 9px;
        line-height: 11px;
        padding: 20px 16px 10px;
        box-sizing: border-box;
        border-bottom: 0.416667px solid #D4E1DF;

        &:first-child {
          flex: 0 0 50%;
          width: 50%;
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
          text-align: right;
        }

        &:nth-child(2) {
          padding-left: 26px;
        }
      }
    }
  }

}

.uzi_body {
  .price__s2 {
    .price__right {
      &:after {
        content: '';
        background: url("/templates/img/paporotnik_uzi.png") no-repeat;
        bottom: -58px;
        background-size: contain;
      }
    }
  }
}
.analiz_body {
  .price__s2 {
    .price__right {
      &:after {
        content: '';
        background: url("/templates/img/paporotnik_analiz.png") no-repeat;
        bottom: -60px;
        background-size: contain;
      }
    }
  }
}
@media (min-width: 594px) {
  .price__wrap {
    #scroll__spoiler_one, #scroll__spoiler_1, #scroll__spoiler_2, #scroll__spoiler_3 {
      max-height: 570px;
      overflow: hidden;
    }
  }
  .price__s2 {
    .price__tabs {
      ul {
        justify-content: center;
      }
    }
  }
}

@media (min-width: 740px) {
  .price__s2 {
    .price__block-spoiler {
      padding-left: 16px;
    }

    h2 {
      text-align: left;
      padding-left: 40px;
      margin-top: 24px;
      margin-bottom: 27px;
    }

    .price__block {
      position: relative;

      .spoiler-block {
        [class*="price__block-item"] {
          margin-right: 0;

        }
      }

      &:before {
        left: -40px;
        right: -40px;
      }

      [class*="price__block-item"] {
        padding: 17px 0;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;

        .row:first-child {
          :first-child {

            padding: 17px 0;
          }

          :last-child {
            display: none;
          }
        }
      }

    }

    .scroll__spoiler {
      max-height: 570px;
    }

    .slider__nav-s2 {
      .slick-list {
        width: 100%;
        margin: 0 6px;
      }

      li {
        font-size: 14px;
        line-height: 134.77%;
      }
    }

    .price__header {
      position: relative;

      .block__button-header {
        display: block;
        position: absolute;
        right: 40px;
        top: -5px;
        width: 258px;
        background: none;

        button {
          @include btn() {
            height: 48px;
            font-size: 14px;
            line-height: 134.77%;
          }
        }
      }
    }

    .price__right {
      top: -40px;
      padding-top: 40px;

      &:after {
        width: 190px;
        bottom: -48px;
        height: 250px;
      }
    }

    .block__button {
      margin-top: -13px;

      &:after {
        width: 250px;
        top: -79px;
        height: 288px;
      }

      .up__down {
        width: 135px;
        bottom: 51px;

        button {

          width: 50px;
          height: 50px;
          border-radius: 16px;
        }
      }
    }

    .spoiler-block {
      [class*="price__block-item"].pretitle_spoiler {
        font-weight: bold;
        border-bottom: 0.4px solid #D4E1DF;
        padding: 25px 0;
        width: 100%;

      }
    }

    &.price__s2_analiz {
      .title__price {
        span {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

  }
  .uzi_body {
    .price__s2 {
      .price__right {
        &:after {
          bottom: -73px;
        }
      }
    }
  }
  .analiz_body {
    .price__s2 {
      .price__right {
        &:after {
          content: '';
          background: url("/templates/img/paporotnik_analiz.png") no-repeat;
          bottom: -77px;
          background-size: contain;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .price__wrap {
    display: flex;
    flex-wrap: nowrap;
  }
  .price__s2 {
    position: relative;

    .lamp {
      position: absolute;
      display: block;
      background: url("/templates/img/lamp.png") center no-repeat;
      width: 750px;
      height: 100%;
      top: 0;
      right: -470px;
      background-size: cover;
    }

    h2 {
      text-align: left;
      padding: 0;
      font-size: 40px;
      line-height: 47px;
    }

    .price__left {
      flex: 0 0 35%;
      max-width: 35%;
      padding-top: 105px;
    }

    .price__right {
      flex: 0 0 65%;
      max-width: 700px;
      padding-top: 78px;
      position: relative;

      padding-left: 24px;

      &:before {
        top: 8px;
        right: -1000%;
      }

      &:after {
        content: '';
        position: absolute;
        background: url("/templates/img/paporotnik.png");
        background-size: contain;
        display: block;
        right: -55%;
        width: 625px;
        bottom: -138px;
        height: 442px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .price__tabs {
      max-width: 400px;
      margin: auto;
      padding: 0 36px 0 0;
      box-sizing: border-box;
      margin-left: 0;

      ul {
        display: flex;

        li {
          display: inline-block;
          margin: auto;
          padding: 18px 17px;
          width: 100%;
          font-weight: normal;
          font-size: px-to-rem(16px);
          line-height: 134.77%;
          height: 56px;
          text-align: left;
          margin-right: 40px;

          &.active {
            box-shadow: 0 10px 30px rgba(101, 80, 68, 0.1);
            background: url("/templates/img/bg_slider_active.png");
            background-color: white;
            position: relative;
            font-weight: 500;

            &:before {
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 28px 0 28px 18px;
              border-color: transparent transparent transparent #FFFFFF;
              position: absolute;
              top: 0;
              left: 100%;
              transform: translateX(0);
            }
          }

          &.slick-slide {
            margin-top: 15px;
          }

          &.slick-current {
            box-shadow: none;
            background-image: url("/templates/img/bg_slider_active.png");
            background-color: initial;
            position: relative;
            border-radius: 8px;
            background-size: 100% 100%;
            background-repeat: no-repeat;

            &:before {
              content: "";
              box-shadow: 0 10px 30px rgba(101, 80, 68, 0.1);
              width: 90%;
              height: 100%;
              position: absolute;
              display: block;
              margin: 1px;
              box-sizing: border-box;
              z-index: -1;
              top: 0;
              left: 0;
              transform: translateX(0);
            }
          }
        }
      }
    }

    .price__header {
      position: relative;

      .block__button-header {
        display: block;
        position: relative;
        top: 56px;
        width: 300px;
        background: none;
        left: 0;

        button {
          @include btn() {
            height: 56px;
            font-size: 16px;
            line-height: 134.77%;
          }
        }
      }
    }

    .block__button {
      margin-top: 0px;
      margin-left: -24px;

      .up__down {
        width: 135px;
        bottom: 51px;

        button {

          width: 50px;
          height: 50px;
          border-radius: 16px;
        }
      }
    }
  }
  .uzi_body {
    .price__s2 {
      .price__right {
        &:after {
          content: '';
          background: url("/templates/img/paporotnik_uzi.png") no-repeat;
          right: -68%;
          bottom: -37px;
        }
      }
    }
  }
  .analiz_body {
    .price__s2 {
      .price__right {
        &:after {
          content: '';
          right: -76%;
          bottom: -97px;
          background-size: auto;
        }
      }
    }
  }
}