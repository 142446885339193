@mixin clearfix() {
  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin min-max($min-width, $max-width, $padding) {
  min-width: $min-width;
  max-width: $max-width;
  padding: 0 $padding;
  box-sizing: border-box;
  margin: 0 auto;
}

@mixin adaptive-video() {
  position: relative;
  padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}

@mixin visually-hidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0,0,0,0);
  overflow: hidden;
}

@function px-to-rem($size, $basicSize: 16px) {
  @return $size / $basicSize#{rem};
}

@mixin dynamicText($minSizePercent, $maxSize, $minSize, $minWidthPx, $maxWidth, $minWidth) {
  font-size: calc(#{$minSizePercent} + (#{$maxSize} - #{$minSize}) * (100vw - #{$minWidthPx}) / (#{$maxWidth} - #{$minWidth}));
}

@mixin btn() {
  width: 100%;
  background: linear-gradient(102.49deg, #3CC684 21.38%, #00B294 162.86%);
  box-shadow: 0 4.78px 7.96667px rgba(100, 203, 160, 0.55);
  border-radius: 8px;
  font-size: px-to-rem(12px);
  line-height: 134.77%;
  text-align: center;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
  display: block;
  padding-top: 14px;
  padding-bottom: 14px;
  text-decoration: none;
  transition-property: background-color;
  transition-duration: 500ms;
  @content;

}