.uslugi__s1 {
  padding-top: 20px;
  text-align: center;
  p {
    font-size: px-to-rem(12px);
    line-height: 160%;
    margin-bottom: 24px;
  }
}

.uslugi__list {
  display: flex;
  flex-wrap: wrap;
  max-width: 1300px;
  margin: auto;
  padding: 0 20px;
}

.uslugi__list-item {
  max-width: 50%;
  flex: 0 0 50%;
  align-items: center;
  position: relative;
  padding: 5px;
  box-sizing: border-box;
  transition: all .5s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 10px 30px rgba(101, 80, 68, 0.1);
    border-radius: 8px;
    transition: all .5s;

    .btn {
      opacity: 0;
      transition: opacity 1s;
    }
  }

  img {
    height: 32px;
    display: block;
    margin: 10px auto 5px;
  }

  .btn {
    @include btn() {
      position: absolute;
      bottom: -26px;
      z-index: 2;
      opacity: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 10px);
      padding: 12px 0;
      transition: opacity 1s;
    }
  }

  span {
    font-weight: 500;
    font-size: px-to-rem(12px);
    line-height: px-to-rem(14px);
  }

  p {
    font-size: px-to-rem(10px);
    line-height: 160%;
    margin-top: 5px;
    margin-bottom: 20px;
  }
}

@media (min-width: 740px) {
  .uslugi__s1 {
    padding-top: 37px;
    padding-bottom: 40px;
    .wrap {
      h2 {
        margin-bottom: 0;
      }
      p {
        font-size: px-to-rem(14px);
        line-height: 160%;
        margin-bottom: 32px;
        padding: 0 100px;
      }
    }
  }

  .uslugi__list-item {
    max-width: 33%;
    flex: 0 0 33%;
    padding: 10px;

    img {
      height: 40px;
      display: block;
      margin: 12px auto 16px;
    }

    span {
      font-size: px-to-rem(14px);
      line-height: px-to-rem(17px);
    }

    p {
      font-size: px-to-rem(12px);
    }
    &:hover {
      .btn {
        opacity: 1;
      }
    }
    .btn {
      @include btn() {
        padding: 15px 0;
        font-size: px-to-rem(14px);
        width: calc(100% - 10px);
      }
    }
  }
  .index_body{
    .uslugi__list-item {
      max-width: 25%;
      flex: 0 0 25%;
    }
  }
}
@media (min-width: 1260px) {
  .uslugi__s1 {
    padding-top: 48px;
    padding-bottom: 65px;
    .wrap {
      h2 {
        font-size: px-to-rem(40px);
        line-height: px-to-rem(47px);
        margin-bottom: 24px;
      }
      p {
        font-size: px-to-rem(20px);
        max-width: 720px;
        margin: 0 auto 40px;
      }
    }
  }
  .uslugi__list {
    justify-content: space-between;
  }
  .uslugi__list-item {
    max-width: 33%;
    flex: 0 0 33%;
    padding: 10px;
    //margin-right: 4%;
    &:nth-child(n+4) {
      //margin-right: 0;
    }
    img {
      height: 56px;
      display: block;
      margin: 38px auto 15px;
    }

    span {
      font-size: px-to-rem(20px);
      line-height: px-to-rem(24px);
    }

    p {
      font-size: px-to-rem(16px);
      margin: 10px auto 60px;
      max-width: 230px;
    }

    .btn {
      @include btn() {
        padding: 19px 0;
        font-size: px-to-rem(16px);
        width: calc(100% - 44px);
      }
    }
  }
  .index_body{
    .uslugi__list-item {
      max-width: 23%;
      flex: 0 0 23%;
    }
  }
}