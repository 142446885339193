
.header__wrap {
  padding-bottom: 25px;
  overflow: hidden;
}
.mobile__menu {
  width: 100%;
  position: absolute;
  top: 6px;
  right: 0;
}
.bg__slider {
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  background-color: radial-gradient(94.29% 258.69% at 19.09% -8.06%, rgba(224, 224, 224, 0.1) 0%, #F0F3F6 100%);

}
.fixed__scroll {
  background: radial-gradient(94.29% 258.69% at 19.09% -8.06%, rgba(224, 224, 224, 0.7) 0%, #F0F3F6 100%);
}
header {
  position: relative;
  padding-top: 92px;

  .slider__dot {
    ul{
      display: flex;
      li{
        margin-right: 20px;
        width: 10px;
        height: 10px;
        border: 1px solid #8C9F9C;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        &.active {
          background: #37B579;
          border-radius: 4px;
          border: none;
        }
      }
    }

  }

  .slider__item {
    color: $greendark;
    max-width: 430px;

    h2 {
      font-weight: bold;
      font-size: px-to-rem(32px);
      line-height: 120%;
      margin-top: 22px;
      margin-bottom: 17px;
      span {
        color: $green;
        white-space: nowrap;
        position: relative;
        z-index: 2;
        padding: 0 13px;

        &:after {
          content: "";
          display: block;
          background: rgba(255, 255, 255, .7);
          border-radius: 8px;
          top: 0;
          left: 2px;
          bottom: -8px;
          right: 7px;
          position: absolute;
          z-index: -1;
        }

      }
    }

    p {
      font-size: px-to-rem(12px);
      line-height: 160%;
    }
  }

  .btn {
    @include btn {
      margin-top: 23px;
      max-width: 220px;
      height: 48px;
    }
  }

  .header__col3 {

    margin: 38px 0 -20px;
    height: 140px;

    ul {
      background: rgba(255, 255, 255, 0.9);
      border-radius: 8px;
      display: flex;
      width: 100%;
      min-width: 540px;
      height: 120px;
      padding: 0;
      box-sizing: border-box;
      box-shadow: 0 10px 54px rgba(0, 0, 0, 0.08);

      li {
        width: 33.333%;
        min-width: 180px;
        padding: 0 20px;
        list-style: none;
        display: inline-block;
        text-align: center;
        font-weight: 500;
        font-size: px-to-rem(10px);
        line-height: 140%;
        box-sizing: border-box;
        border-right: 1px solid #D4E1DF;

        &:last-child {
          border-right: none
        }

        img {
          margin: 16px auto 0;
          display: block;
          height: 40px;
        }
      }
    }
  }
}

@media (min-width: 594px) {
  header {
    .header__col3 {

      margin: 38px 0 0 0;

      ul {
        margin: 0;
        padding: 0;
      }
    }
  }
}

@media (min-width: 740px) {
  header {
    padding-top: 135px;
    padding-bottom: 12px;

    .slider__item {
      h2 {
        margin-top: 26px;
        margin-bottom: 20px;
      }

      p {
        font-size: px-to-rem(14px);
        max-width: 344px;
      }

      .bg__slider {
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -2;
        display: none;
        &.active {
          display: block;
        }
      }
    }

    .btn {
      @include btn {
        margin-top: 37px;
        max-width: 258px;
        font-size: px-to-rem(14px);
        line-height: 134.77%;
      }
    }

    .header__col3 {
      margin: 75px 0 0 0;
      height: 140px;

      ul {
        height: 136px;

        li {
          font-weight: 500;
          font-size: px-to-rem(12px);
          line-height: 140%;
          padding: 0 24px;

          p {
            margin: 8px auto 0;
            max-width: 220px;

          }
        }
      }
    }
  }

}

@media (min-width: 1200px) {
  .mobile__menu {
    width: 100%;
    position: initial;
    top: 0;
    right: 0;
  }
  header {
    padding-top: 168px;
    position: relative;
    .slider__dot {
      padding-left: 50px;
      ul{
        li{
          margin-right: 27px;
          width: 13.5px;
          height: 13.5px;
          border-radius: 5px;
          &.active {
            border-radius: 5px;
          }
        }
      }

    }
    .slider__item {
      padding-left: 50px;
      max-width: 600px;
      h2 {
        font-size: px-to-rem(48px);
        margin-top: 33px;
      }

      p {
        font-size: px-to-rem(20px);
        max-width: 500px;
      }

      .bg__slider {
          background-size: auto 100%;
      }
    }

    .btn {
      @include btn {
        max-width: 300px;
        height: 56px;
        font-size: px-to-rem(16px);
        margin-top: 45px;
      }
    }

    .header__col3 {
      margin: 113px 0 0 0;
      height: 140px;

      ul {
        height: 136px;
        li {
          font-weight: 500;
          font-size: px-to-rem(16px);
          line-height: 140%;
          padding: 0 50px;
          display: flex;
          align-self: center;
          align-items: center;
          height: 100%;
          img {
            margin: 0 19px 0 0 ;
            height: 48px;
          }
          p {
            margin-top: 8px;
            max-width: 295px;
            text-align: left;
          }
        }
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background: radial-gradient(94.29% 258.69% at 19.09% -8.06%, rgba(224, 224, 224, 0.1) 0%, #F0F3F6 100%);      width: 100%;
      height: 100%;
      display: block;
      z-index: -3;
    }
  }
}