a {
  cursor: pointer;
}
.modal-open {
  overflow: hidden;
  margin-right: 17px;
  position: relative;

  .modal-bg {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(221, 234, 232, 0.6);

  }

  .container, footer {
    filter: blur(26px);
  }

  .fixed {
    left: -17px;
  }
}

#submit, #submit-s8 {
  &.disabled {
    color: #979595;
    background: #c4c4c4;
    box-shadow: none;
    cursor: default;

    &:hover {
      color: #979595;
      background: #c4c4c4;
      box-shadow: none;
      cursor: default;
    }
  }
}

.modal-bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal__body {
  &.hide {
    display: none;
  }
}

.modal__spasibo {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 56px;
  left: 50%;
  width: 100%;
  padding: 40px;

  &.visible {
    display: block;
  }

  p {
    font-size: px-to-rem(30px);
  }
}

.modal, .modal_lic {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 10;
  padding: 70px 40px 40px;

  &.visible {
    display: block;
  }

  .modal-call__title {
    font-weight: bold;
    font-size: px-to-rem(24px);
    line-height: px-to-rem(28px);
    text-align: center;
    margin: 0;
  }

  p {
    text-align: center;
    font-size: px-to-rem(12px);
    line-height: 160%;
  }

  input {
    background: rgba(212, 225, 223, .2);
    border-radius: 8px;
    border: none;
    font-size: px-to-rem(14px);
    line-height: 134.77%;
    color: $greendark;
    width: 100%;
    height: 48px;
    padding: 0 20px;
    box-sizing: border-box;
    margin-top: 16px;
  }

  .block-button {
    .btn {
      @include btn {
        box-shadow: 0 9.15972px 15.2662px rgba(100, 203, 160, 0.55);
        margin-top: 24px;
        font-weight: bold;
        font-size: px-to-rem(14px);
        line-height: 134.77%;
      }
    }
  }
}

.modal_lic {
  z-index: 1000;

  .consult__body {
    overflow: auto;
    height: 100%;
    margin: 0;
    max-width: 100%;
  }

  p {
    text-align: left;
  }
}

.consult__body {
  max-width: 360px;
  min-width: 240px;
  margin: auto;

  a {
    color: #3cc684;
    cursor: pointer;
  }

  .consult__pers__data {
    span {
      color: #3cc684;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.closed__modal, .closed__modal_lic {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 1px solid #8C9F9C;
  box-sizing: border-box;
  border-radius: 12px;
  text-align: center;

  b {
    font-size: px-to-rem(28px);
    transform: rotate(45deg);
    position: absolute;
    font-weight: normal;
    left: 14px;
    top: 1px;
  }

}

@media (min-width: 594px) {

  .modal, .modal_lic {
    z-index: 999;
    border-radius: 16px;
    top: 50%;
    left: 50%;
    padding: 40px 40px 40px;
    max-width: 450px;
    min-width: 400px;
    height: 480px;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: border-box;
    box-shadow: 0 10px 30px rgba(68, 101, 91, 0.08);

    p {
      font-size: px-to-rem(12px);
    }

    input {
      font-size: px-to-rem(20px);
      line-height: 134.77%;
      color: $greendark;
      width: 100%;
      height: 56px;
      padding: 0 20px;
      margin-top: 16px;
    }

    .block-button {
      .btn {
        @include btn {
          font-size: px-to-rem(16px);
        }
      }
    }

    .closed__modal {
      right: -50px;
      top: -37px;
    }
  }

  .modal_lic {
    z-index: 1000;
    max-width: 650px;
    min-width: 550px;
    height: 680px;
    max-height: 100%;
    padding-top: 70px;
  }
}

.texteeee {

  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 10;
  padding: 136px 40px 40px;
  max-width: 450px;
  height: 480px;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: border-box;
}